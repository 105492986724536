import classNames from 'classnames';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

export function ActiveLink(
  props: LinkProps & {
    activeNames?: string;
    end?: boolean;
  }
) {
  const { activeNames, className, to, end = true, ...restProps } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end });

  return (
    <Link
      {...restProps}
      to={to}
      className={classNames(className, { [`${activeNames}`]: match })}
    />
  );
}
