import GitHubText from '../../../assets/images/github-text.svg';
import GitHubIcon from '../../../assets/images/github-icon.svg';

interface IGitHubLogoProps {
  containerClassName?: string;
  iconClassName?: string;
  textClassName?: string;
}

export default function GitHubLogo({
  containerClassName,
  iconClassName,
  textClassName,
}: IGitHubLogoProps) {
  return (
    <div
      className={`inline-flex items-center justify-center space-x-2 ${containerClassName}`}
    >
      <img src={GitHubIcon} alt='GitHub Icon' className={iconClassName} />
      <img src={GitHubText} alt='GitHub Text' className={textClassName} />
    </div>
  );
}
