import classNames from 'classnames';
import { memo, ReactNode } from 'react';

function LayoutBodyCard({
  children,
  className,
}: {
  className?: string;
  children: ReactNode;
}) {
  const hasBgClass = !!className?.split(' ')?.find((cn) => cn.includes('bg-'));
  return (
    <div
      className={classNames(
        'rounded-2xl drop-shadow-xl',
        { 'bg-base-100': !hasBgClass },
        className
      )}
    >
      {children}
    </div>
  );
}

export default memo(LayoutBodyCard);
