import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ActiveLink } from '../../components/ActiveLink';
import { UserAvatar } from '../../modules/auth/UserAvatar';

export default function ProfileMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    setOpen(false);
  }

  return (
    <div
      className='dropdown dropdown-end'
      onBlur={() => {
        setOpen(false);
      }}
    >
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className='btn btn-ghost btn-circle avatar'
      >
        <UserAvatar />
      </button>
      <ul
        className={classNames(
          'mt-3 p-2 shadow bg-base-100 rounded-box w-52',
          open ? 'menu menu-compact dropdown-content ' : 'hidden'
        )}
      >
        <li>
          <ActiveLink
            to='/profile'
            onMouseDown={() => {
              navigate('/profile');
              handleClick();
            }}
            activeNames='active'
            className='justify-between'
          >
            Profile
          </ActiveLink>
        </li>
        <li>
          <ActiveLink
            to='/settings'
            onMouseDown={() => {
              navigate('/settings');
              handleClick();
            }}
            activeNames='active'
            className='justify-between'
          >
            Settings
          </ActiveLink>
        </li>
        <li>
          <ActiveLink
            to='/'
            onMouseDown={() => {
              navigate('/auth/login');
              handleClick();
            }}
            activeNames='active'
            className='justify-between'
          >
            Login
          </ActiveLink>
        </li>
      </ul>
    </div>
  );
}
