import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { AToastAutoDismiss } from './toast';

export default function ToastContainerConfig() {
  const [autoDismiss] = useRecoilState(AToastAutoDismiss);
  return (
    <ToastContainer
      autoClose={autoDismiss}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
