import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

function PaleButton({
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classNames(
        'btn uppercase hover:bg-opacity-100 hover:bg-base-300 bg-base-200 bg-opacity-100 border-0 text-base-content px-3',
        className
      )}
      {...props}
    >
      {props.children}
    </button>
  );
}

export default memo(PaleButton);
