const backendApi = process.env.REACT_APP_ZETTA_BACKEND_API || '';

export function getEnvs() {
  const envs = {
    BackendUrl: process.env.REACT_APP_BACKEND_URL,
    ZettaBackendApiPrivate: `${backendApi}/qugate/v1`,
    ZettaBackendApiPublic: `${backendApi}/pubgate/v1`,
  };

  return envs;
}

export default getEnvs();
