import { useQuery } from '@apollo/client';
import * as React from 'react';
import GET_ME from './graphql/GET_ME';
import { GetMe } from './graphql/__generated__/GetMe';

export function UserAvatar() {
  const result = useQuery<GetMe>(GET_ME);

  if (!result.data?.me?.profileImgUrl) {
    return (
      <div className='avatar placeholder'>
        <div className='bg-neutral-focus text-neutral-content rounded-full w-10'>
          <span className='text-md'>JO</span>
        </div>
      </div>
    );
  }

  return (
    <div className='avatar'>
      <div className='w-10 rounded-full bg-slate-400'>
        <img src={result.data?.me?.profileImgUrl} />
      </div>
    </div>
  );
}
