/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCreateDashboardRequest,
  ModelCreateDashboardResponse,
  ModelDeleteDashboardResponse,
  ModelGetDashboardResponse,
  ModelUpdateDashboardRequest,
  ModelUpdateDashboardResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Dashboards<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create dashboard
   *
   * @tags example
   * @name DashboardsCreate
   * @summary create dashboard
   * @request POST:/dashboards
   */
  dashboardsCreate = (
    request: ModelCreateDashboardRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateDashboardResponse, any>({
      path: `/dashboards`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get dashboard
   *
   * @tags example
   * @name DashboardsDetail
   * @summary get dashboard
   * @request GET:/dashboards/{id}
   */
  dashboardsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetDashboardResponse, any>({
      path: `/dashboards/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update dashboard
   *
   * @tags example
   * @name DashboardsCreate2
   * @summary update dashboard
   * @request POST:/dashboards/{id}
   * @originalName dashboardsCreate
   * @duplicate
   */
  dashboardsCreate2 = (
    id: string,
    request: ModelUpdateDashboardRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateDashboardResponse, any>({
      path: `/dashboards/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete dashboard
   *
   * @tags example
   * @name DashboardsDelete
   * @summary delete dashboard
   * @request DELETE:/dashboards/{id}
   */
  dashboardsDelete = (id: string, params: RequestParams = {}) =>
    this.request<ModelDeleteDashboardResponse, any>({
      path: `/dashboards/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
