import classNames from 'classnames';
import { HTMLAttributes, memo, useState } from 'react';
import Truncate from 'react-truncate';

const ReadMore = memo(
  ({ className, children, ...props }: HTMLAttributes<HTMLAnchorElement>) => {
    const [open, setOpen] = useState(false);
    return (
      <Truncate
        lines={open ? 0 : 10}
        ellipsis={
          <>
            ...
            <br />
            <div>
              <a
                {...props}
                className={classNames(
                  'hover:text-primary font-bold',
                  className
                )}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Show all
              </a>
            </div>
          </>
        }
      >
        {children}
      </Truncate>
    );
  }
);

export default ReadMore;
