import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import Cookies from 'universal-cookie';
import { Dashboards } from './__gen__/Dashboards';
import { Databases } from './__gen__/Databases';
import { Queries } from './__gen__/Queries';
import { Charts } from './__gen__/Charts';
import { Stream } from './__gen__/Stream';
import { Queryruns } from './__gen__/Queryruns';
import { My } from './__gen__/My';
import envs from '../config/envs';

const applyErrorHandler = <T>(client: T) => {
  // @ts-ignore
  (client.instance as AxiosInstance).interceptors.response.use(
    (response) => response,
    (error) => {
      // eslint-disable-next-line
      console.log('global error handler', error);
      if (error.response.status === 401) {
        // handle auth
        // eslint-disable-next-line
        console.log('user not authorized');
      }

      // all other exceptions forwarded to requester
      throw error;
    }
  );
  return client;
};

export const getAuthHeader = (): AxiosRequestHeaders => {
  const cookie = new Cookies();
  const auth = cookie.get('auth');

  if (auth && auth.username) {
    const token = `${window.btoa(`${auth.username}:${auth.password}`)}`;
    return {
      Authorization: `Basic ${token}`,
    };
  }

  return {};
};

export const ChartApi = () => {
  const authHeader = getAuthHeader();

  return new Charts({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};

export const PublicChartApi = () => {
  const authHeader = getAuthHeader();

  return new Charts({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPublic,
  });
};

export const PublicQueryApi = () => {
  const authHeader = getAuthHeader();

  return new Queries({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPublic,
  });
};

export const QueryApi = () => {
  const authHeader = getAuthHeader();

  return new Queries({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};

export const DatabaseApi = () => {
  const authHeader = getAuthHeader();

  const client = new Databases({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });

  return applyErrorHandler(client);
};

export const DashboardApi = () => {
  const authHeader = getAuthHeader();

  const client = new Dashboards({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });

  return applyErrorHandler(client);
};

export const StreamApi = () => {
  const authHeader = getAuthHeader();

  return new Stream({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};

export const QueryRunsApi = () => {
  const authHeader = getAuthHeader();

  return new Queryruns({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};

export const MyApi = () => {
  const authHeader = getAuthHeader();

  return new My({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};

// TODO: replace it later. this is for demo perpose
export const UsersApi = () => {
  const authHeader = getAuthHeader();

  return new Charts({
    headers: authHeader,
    baseURL: envs.ZettaBackendApiPrivate,
  });
};
