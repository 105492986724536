import { IDataset, ITable } from '../const';

export const DATASETS: IDataset[] = [
  {
    id: 14,
    contractAddress: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    name: 'XRP',
    pathKey: 'ripple',
    chain: 'Binance',
    schedule: 'Realtime',
    tags: ['Blockchain', 'DEX', 'Payment Protocol'],
    labels: [
      'Blockchain',
      'Decentralized Exchange',
      'Real-time',
      'Payment Protocol',
      'Settlement',
    ],
    imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/200x200/52.png',
    overview:
      'Ripple (XRP) is a decentralized, public blockchain. Launched in 2013, XRP Ledger aims to complement traditional payments, migrating transactions that occur today between databases controlled by financial institutions to a more open infrastructure.',
    link: 'https://xrpl.org/',
    github: 'https://github.com/ripple/rippled/',
    creators: ['David Schwartz', 'Jed McCaleb', 'Arthur Britto'],
    tokenAddr: 'N/A',
    created: '2012',
  },
  {
    id: 4,
    contractAddress: '7wDh4VCTPwx41kvbLE6fkFgMEjnqw7NpGJvQtNabCm2B',
    name: 'Friktion Labs',
    pathKey: 'friktion',
    chain: 'Solana',
    schedule: 'Batch',
    tags: ['DeFi', 'Portfolio Manager', 'Crypto'],
    labels: ['DeFi', 'Portfolio Manager', 'Options Protocol', 'Solana'],
    imgUrl:
      'https://pbs.twimg.com/profile_images/1464652673031057417/JcuTHH12_400x400.png',
    overview:
      'Friktion is a crypto-asset portfolio management platform, built to perform across market cycles. Volts, Friktion’s native structured products, offer sustainable income generation and volatility yield strategies. Designed for individuals and DAOs.',
    link: 'https://friktion.fi/',
    github: 'https://github.com/Friktion-Labs/sdk',
    creators: ['Uddhav Marwaha', 'Alex Dai'],
    tokenAddr: 'VoLT1mJz1sbnxwq5Fv2SXjdVDgPXrb9tJyC8WpMDkSp',
    created: '2021',
  },
  {
    id: 10,
    contractAddress: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    name: 'Polygon',
    pathKey: 'polygon',
    chain: 'Polygon',
    schedule: 'Scheduled',
    tags: ['Blockchain', 'Crypto'],
    labels: ['Blockchain', 'Layer2', 'EVM Compatible', 'Scaling'],
    imgUrl:
      'https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/DPYBKVZG55EWFHIK2TVT3HTH7Y.png',
    overview:
      'Polygon is a Layer 2 scaling solution whose aim is to provide multiple tools to reduce the cost and complexities of blockchains while improving transaction speeds. It is a framework for building interconnected blockchain networks.',
    link: 'https://polygon.technology/ ',
    github: 'https://github.com/maticnetwork/contracts ',
    creators: ['Jaynti Kanani', 'Sandeep Nailwal', 'Anurag Arjun'],
    tokenAddr: 'N/A',
    created: '2017',
  },
];

export const TABLES: ITable[] = [
  { id: 1, name: 'ledgers' },
  { id: 2, name: 'transactions' },
  { id: 3, name: 'affected_nodes' },
  { id: 4, name: 'memos' },
  { id: 5, name: 'signers' },
];
