import { IoIosArrowForward } from 'react-icons/io';

import classNames from 'classnames';
import PaleButton from '../../../components/interactive/PaleButton';
import { HTMLAttributes } from 'react';

export default function ConnectTypeButton({
  className,
  active,
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
}) {
  return (
    <PaleButton
      className={classNames('w-full normal-case', className)}
      style={{ backgroundColor: !active ? 'transparent' : '' }}
      {...props}
    >
      <div className='opacity-80 flex items-center w-full'>
        {children}
        {active && (
          <IoIosArrowForward size='.8rem' className='opacity-50 flex-grow-0' />
        )}
      </div>
    </PaleButton>
  );
}
