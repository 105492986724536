import { DatasetLayoutType } from '../../../types';

export default function getParamsFromQueryParams(
  searchParams: URLSearchParams
) {
  const filters = new Set<string>();
  searchParams.forEach((value, key) => {
    if (value === 'filter') {
      const filter_parts = key.split('-');
      const [filter_type, filter_value] =
        filter_parts.length !== 2 ? [] : filter_parts;
      filters.add(`${filter_type}: ${filter_value}`);
    }
  });
  return {
    search: searchParams.get('search') || '',
    view_type: (searchParams.get('view_type') as DatasetLayoutType) || 'grid',
    filters,
  };
}
