import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useState } from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import ProfileMenu from './components/ProfileMenu';
import Logo from '../components/app/Logo';

export const RoutePaths = {
  projects: 'projects',
};

const NAV_ITEMS = [
  { name: 'Dashboards', path: 'dashboards' },
  { name: 'Projects', path: RoutePaths.projects, badgeLabel: 'alpha' },
  { name: 'New Query', path: 'query-builder' },
  { name: 'Connectors' },
  {
    name: 'Docs',
    external_path: 'https://docs.zettablock.com/',
    opens_new_tab: true,
  },
];

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className='mx-auto navbar px-0 py-7'>
      <div className='hidden pr-2'>
        <div className='dropdown'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <HiMenuAlt2 size='1.4rem' />
          </button>
          <ul
            className={classNames(
              'menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52',
              openMenu ? 'block' : 'hidden'
            )}
          >
            {NAV_ITEMS.map((nav) => (
              <li key={nav.name}>
                {nav.external_path ? (
                  <a
                    href={nav.external_path}
                    {...(nav.opens_new_tab && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    onClick={() => {
                      setOpenMenu(false);
                    }}
                    to={
                      nav.path || `/${nav.name.toLowerCase().replace(' ', '-')}`
                    }
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className='flex-none'>
        <button type='button' className='text-3xl font-bold text-primary'>
          <NavLink to={'/dashboards'}>
            <Logo />
          </NavLink>
        </button>
      </div>

      <div className='flex-none flex px-7'>
        <ul className='menu menu-horizontal p-0'>
          {NAV_ITEMS.map((nav) => {
            return (
              <li key={nav.name}>
                {nav.external_path ? (
                  <a
                    href={nav.external_path}
                    {...(nav.opens_new_tab && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    to={
                      nav.path || `/${nav.name.toLowerCase().replace(' ', '-')}`
                    }
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}

                    {nav.badgeLabel && (
                      <span className='absolute indicator-item badge badge-sm badge-secondary top-[-0.3rem] right-[-0.3rem]'>
                        {nav.badgeLabel}
                      </span>
                    )}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className='flex-1 text-right'>
        <div className='flex-1' />
        {
          // TODO: enable theme when it's ready
          // <div className='flex-none h-7 px-4'>
          //   <ThemeSwitch />
          // </div>
        }
        <div className='flex-none'>
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
}
