import { BiSearch } from 'react-icons/bi';

export default function SearchBox() {
  return (
    <div className='relative'>
      <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
        <button
          type='submit'
          className='p-2 focus:outline-none focus:shadow-outline'
        >
          <BiSearch size='1.3rem' className='opacity-60' />
        </button>
      </span>
      <input
        type='text'
        placeholder='Search'
        className='input w-full text-lg py-4 rounded-xl pr-12 focus:outline-none bg-base-200 text-content'
      />
    </div>
  );
}
