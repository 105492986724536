import { useNavigate } from 'react-router';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import SubmitUpload from './SubmitUpload';
import ProcessingUpload from './ProcessingUpload';
import UploadDetails from './UploadDetails';
import { useState } from 'react';
import { UploadPayload } from './types';
import { isEqual } from 'lodash';

type UploadStatus = 'details' | 'submit' | 'processing';

export default function Upload() {
  const navigate = useNavigate();
  const [s3Url, setS3Url] = useState<string>();
  const [file, setFile] = useState<File>();
  const [uploadPayload, setUploadPayload] = useState<UploadPayload>();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('details');

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      {uploadStatus === 'details' && (
        <UploadDetails
          selectedFile={file}
          onPrev={() => navigate(-1)}
          onNext={(url, uploadFile) => {
            setS3Url(url);
            setFile(uploadFile);
            setUploadStatus('submit');
          }}
        />
      )}
      {uploadStatus === 'submit' && s3Url && file && (
        <SubmitUpload
          tableName={uploadPayload?.table}
          s3={s3Url}
          file={file}
          onPrev={() => setUploadStatus('details')}
          onNext={(payload) => {
            if (!isEqual(payload, uploadPayload)) {
              setUploadPayload(payload);
            }
            setUploadStatus('processing');
          }}
        />
      )}
      {uploadStatus === 'processing' && uploadPayload && (
        <ProcessingUpload
          payload={uploadPayload}
          onPrev={() => setUploadStatus('submit')}
          onNext={() => navigate('/query-builder')}
        />
      )}
    </LayoutBodyCard>
  );
}
