import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { enableMapSet } from 'immer';
import { RecoilRoot } from 'recoil';

import Layout from './layouts/Layout';
import FourOFour from './modules/fourofour/FourOFour';
import DatasetList from './modules/dataset/DatasetList';
import { createClient } from './modules/apollo/apollo';
import Profile from './modules/profile/Profile';
import ScrollToTop from './components/app/ScrollToTop';
import ConnectorView from './modules/connectors/ConnectorView';
import Transformations from './modules/transformations/Transformations';
import Login from './modules/auth/Login';
import PageLoading from './components/app/PageLoading';
import FullPageLoading from './components/app/FullPageLoading';
import { RecoilExternalStatePortal } from './modules/recoil/utils';
import ToastContainerConfig from './modules/toast/ToastContainerConfig';
import { RoutePaths } from './layouts/Navbar';
import Upload from './modules/connectors/Upload';
import NewConnector from './modules/connectors/NewConnector';

enableMapSet();

const client = createClient();

const DatasetDetail = lazy(() => import('./modules/dataset/DatasetDetail'));
const QueryBuilder = lazy(() => import('./modules/queryBuilder/QueryBuilder'));
const GEMExampleIframe = lazy(() => import('./modules/chart/GEMExampleIframe'));
const StandaloneChart = lazy(
  () => import('./modules/chart/StandaloneChartPage')
);
const DashboardViewPage = lazy(
  () => import('./modules/dashboard/DashboardViewPage')
);
const DashboardList = lazy(() => import('./modules/dashboard/DashboardList'));

export default function App() {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path='/embed/:chartId'
              element={
                <Suspense fallback={<FullPageLoading />}>
                  <StandaloneChart />
                </Suspense>
              }
            />
            <Route
              path='/'
              element={<Navigate to='/query-builder' replace />}
            />
            <Route path='/' element={<Layout />}>
              <Route
                path='gem'
                element={
                  <Suspense fallback={<PageLoading />}>
                    <GEMExampleIframe />
                  </Suspense>
                }
              />
              <Route path='auth'>
                <Route path='login' element={<Login />} />
              </Route>
              <Route path='dashboards'>
                <Route
                  index
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <DashboardList />
                    </Suspense>
                  }
                />
                <Route
                  path=':dashboardId'
                  element={
                    <Suspense
                      fallback={<PageLoading className='bg-transparent' />}
                    >
                      <DashboardViewPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path='profile'>
                <Route index element={<Profile />} />
              </Route>
              <Route
                path='query-builder/:id'
                element={
                  <Suspense fallback={<PageLoading />}>
                    <QueryBuilder />
                  </Suspense>
                }
              />
              <Route
                path='query-builder'
                element={
                  <Suspense fallback={<PageLoading />}>
                    <QueryBuilder />
                  </Suspense>
                }
              />
              <Route path='connectors'>
                <Route index element={<NewConnector />} />
                <Route path='type/:type' element={<Upload />} />
                <Route path=':id/*' element={<ConnectorView />} />
              </Route>
              <Route path='transformations' element={<Transformations />} />
              <Route path={RoutePaths.projects}>
                <Route index element={<DatasetList />} />
                <Route
                  path=':datasetId/*'
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <DatasetDetail />
                    </Suspense>
                  }
                />
                <Route path='*' element={<DatasetList />} />
              </Route>
              <Route path='*' element={<FourOFour />} />
            </Route>
          </Routes>
        </Router>
        <ToastContainerConfig />
        <RecoilExternalStatePortal />
      </ApolloProvider>
    </RecoilRoot>
  );
}
