import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import LayoutBodyCard from '../cards/LayoutBodyCard';
import LogoBlinkerCenter from './LogoBlinkerCenter';

export default function PageLoading({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <LayoutBodyCard
      className={classNames('p-24 flex justify-center', className)}
    >
      <LogoBlinkerCenter />
    </LayoutBodyCard>
  );
}
