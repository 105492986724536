import qs from 'query-string';

export function readQueryParams() {
    return qs.parse(window.location.search);
}

export function updateQueryParams(key: string, value: unknown) {
    const params = readQueryParams()
    const newParams = {
        ...params,
        [key]: value,
    };
    return qs.stringify(newParams);
}

export function deleteQueryParams(key: string) {
    const params = readQueryParams()
    delete params[key]
    return qs.stringify(params);
}