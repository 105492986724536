import LayoutBodyCard from '../../components/cards/LayoutBodyCard';

export default function FourOFour() {
  return (
    <LayoutBodyCard>
      <div className='py-12 text-center'>
        <div className='stack'>
          <div className='card shadow-md bg-primary text-primary-content '>
            <div className='card-body'>
              <h2 className='card-title'>Under construction</h2>
              <p className='text-left'>{"It's on its way..."}</p>
            </div>
          </div>
          <div className='card shadow bg-primary text-primary-content'>
            <div className='card-body'></div>
          </div>
          <div className='card shadow-sm bg-primary text-primary-content'>
            <div className='card-body'></div>
          </div>
          <div className='card shadow-sm bg-primary text-primary-content'>
            <div className='card-body'></div>
          </div>
        </div>
      </div>
    </LayoutBodyCard>
  );
}
