import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../layouts/Navbar';
import { DatasetLayoutType } from '../../../types';
import { IDataset } from '../const';

interface ICardProps {
  type?: DatasetLayoutType;
  dataset: IDataset;
}

interface IDisplayCardProps {
  dataset: IDataset;
}

function GridCard({ dataset }: IDisplayCardProps) {
  const { name, imgUrl, tags, img, pathKey } = dataset;
  return (
    <div className='w-1/3 p-3'>
      <Link
        to={`/${RoutePaths.projects}/${pathKey}/overview`}
        className='p-6 grid-card flex bg-gray-50 rounded-2xl w-full h-full'
      >
        <div className='image-container w-1/3'>
          <img
            className='rounded-xl w-full h-auto'
            src={img || imgUrl}
            alt=''
          />
        </div>
        <div className='w-6' />
        <div className='flex flex-col w-2/3'>
          <h3 className='text-3xl overflow-hidden whitespace-nowrap'>{name}</h3>
          <div className='flex-1'>
            <div className='flex items-start flex-wrap justify-start py-2'>
              {tags.map((tag) => (
                <span
                  key={tag}
                  className='badge badge-ghost badge-outline opacity-50 p-1 px-2 mr-2 mb-2'
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
function ListCard({ dataset }: IDisplayCardProps) {
  const { id, chain, name, schedule, contractAddress, imgUrl, tags } = dataset;
  return (
    <Link
      to={`/${RoutePaths.projects}/${id}/overview`}
      className='h-[25vh] p-6 list-card inline-flex bg-gray-50 rounded-2xl items-center hover:scale-[1.01] hover:cursor-pointer transition-all'
      style={{ width: 'calc(100% - 80px)' }}
    >
      <div className='image-container h-full'>
        <img className='rounded-xl h-full w-auto' src={imgUrl} alt='' />
      </div>
      <div className='w-6' />
      <div className='flex-1 flex-col h-full w-full max-w-[85%]'>
        <h3 className='text-3xl overflow-hidden whitespace-nowrap'>{name}</h3>
        <p className='text-gray-400 text-sm overflow-hidden whitespace-nowrap'>
          {contractAddress}
        </p>
        <div className='h-1/5' />
        <p className='text-gray-400 text-md font-bold overflow-hidden whitespace-nowrap'>
          {chain}
        </p>
        <p className='text-gray-400 text-md font-bold overflow-hidden whitespace-nowrap'>
          {schedule}
        </p>
        <div className='flex flex-wrap justify-start space-x-2'>
          {tags.map((tag) => (
            <span className='min-w-auto text-gray-500 bg-white p-1 px-3 rounded-xl'>
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </Link>
  );
}

function Card({ type = 'list', dataset }: ICardProps) {
  return type === 'grid' ? (
    <GridCard dataset={dataset} />
  ) : (
    <ListCard dataset={dataset} />
  );
}

export default Card;
