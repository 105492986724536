import { ReactNode, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Footer from './components/Footer';
import Navbar from './Navbar';
import { isEmpty, startsWith } from 'lodash';

interface IProps {
  children?: ReactNode;
}

function AuthRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(['auth']);

  useEffect(() => {
    const isAuthRoute = startsWith(location.pathname, '/auth');
    if (!isAuthRoute && isEmpty(cookies.auth)) {
      const backParam = encodeURIComponent(location.pathname);

      navigate(`/auth/login${backParam ? `?back=${backParam}` : ''}`);
    }
  }, [cookies.auth, location.pathname]);

  return null;
}

function Layout({ children }: IProps) {
  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className='container min-w-[1280px] w-[1280px] mx-auto px-5'>
      <AuthRouter />
      <Navbar />
      {children || <Outlet />}
      <Footer />
    </div>
  );
}

export default Layout;
