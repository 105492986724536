import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import UserDashboard from './components/UserDashboard';
import UserInfoPanel from './components/UserInfoPanel';

export default function Profile() {
  return (
    <div className='flex flex-wrap lg:flex-nowrap items-start'>
      <LayoutBodyCard className='lg:basis-2/5 basis-full p-5'>
        <UserInfoPanel />
      </LayoutBodyCard>
      <div className='basis-6 h-6' />
      <LayoutBodyCard className='basis-full'>
        <UserDashboard />
      </LayoutBodyCard>
    </div>
  );
}
