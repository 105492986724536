import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

function BodyHeader3({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h3 className={classNames('text-xl font-bold', className)} {...props}>
      {children}
    </h3>
  );
}

export default memo(BodyHeader3);
