import { DATASET_FILTER_OPTS, IDataset } from "../const";

export default function organizeDataset(datasets: IDataset[], currentFilters: Set<string>): [{ [key: string] : number }, Set<number>] {
    const filters = DATASET_FILTER_OPTS.filter(({type, value}) => currentFilters.has(`${type}: ${value}`) )
    const countsPerFilter: { [key: string] : number } = {}
    const filteredDatasets = new Set<number>()
    datasets.forEach((dataset) => {
        filters.forEach(({type, name, value}) => {
            const key = `${type}: ${name}`
            if(value === 'all' || dataset[type].toLocaleLowerCase() === value) {
                filteredDatasets.add(dataset.id)
                if(countsPerFilter[key]) {
                    countsPerFilter[key] += 1
                } else {
                    countsPerFilter[key] = 1
                }
            }
        })
    })
    return [countsPerFilter, filteredDatasets]
}
