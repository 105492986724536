import { MdRefresh, MdUpload, MdPlayArrow } from 'react-icons/md';

import { HorizontalLine } from '../../../components/HorizontalLine';
import { VerticalLine } from '../../../components/VerticalLine';

export default function Status() {
  return (
    <div className='pt-6'>
      <div className='flex px-6'>
        <div className=''>Sync History</div>
        <div className='flex-1' />
        <div className='flex'>
          <div className='btn btn-sm btn-ghost'>1 HOUR</div>
          <div className='w-5' />
          <div className='btn btn-sm btn-ghost'>1 DAY</div>
          <div className='w-5' />
          <button className='btn btn-sm btn-primary'>1 WEEK</button>
          <div className='w-5' />
        </div>
      </div>
      <div className='h-6' />
      {/* timeline graph */}
      <div className='flex px-6'>
        <div className='border w-full p-10 opacity-60 bg-base-200'>
          TIMELINE GRAPH TO BE IMPLEMENTED
        </div>
      </div>
      <div className='h-12' />
      <HorizontalLine />
      <div className='flex'>
        <div className='flex-1'>
          <div className='p-6 flex flex-col items-center'>
            <div className='text-xl'>Alerts</div>
            <div className='h-6' />
            <div className='opacity-50'>No alerts. Good job!</div>
          </div>
        </div>
        <VerticalLine />
        <div className='flex-1'>
          <div className='p-6 flex flex-col items-center'>
            <div className='text-xl'>User Actions</div>
            <div className='h-3' />
            <div className='border-b w-full py-3'>
              <div className='flex'>
                <div className='text-primary'>
                  <MdRefresh size='1.3rem' />
                </div>
                <div className='w-3' />
                <div className='flex-1'>
                  <div>Manual Update Triggered</div>
                  <div className='opacity-50 text-sm'>by Chi Zhang</div>
                  <div className='opacity-50 text-sm'>
                    May 22, 2022 12:44 PM
                  </div>
                </div>
              </div>
            </div>
            <div className='border-b w-full py-3'>
              <div className='flex'>
                <div className='text-primary'>
                  <MdPlayArrow size='1.3rem' />
                </div>
                <div className='w-3' />
                <div className='flex-1'>
                  <div>Connection Resumed</div>
                  <div className='opacity-50 text-sm'>by Chi Zhang</div>
                  <div className='opacity-50 text-sm'>
                    May 22, 2022 12:44 PM
                  </div>
                </div>
              </div>
            </div>
            <div className='border-b w-full py-3'>
              <div className='flex'>
                <div className='text-primary'>
                  <MdUpload size='1.3rem' />
                </div>
                <div className='w-3' />
                <div className='flex-1'>
                  <div>Schema Changed</div>
                  <div className='opacity-50 text-sm'>by Chi Zhang</div>
                  <div className='opacity-50 text-sm'>
                    May 22, 2022 12:44 PM
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VerticalLine />
        <div className='flex-1'>
          <div className='p-6 flex flex-col items-center'>
            <div className='text-xl'>Stats</div>
            <div className='h-6' />
            <div className='text-3xl text-primary'>21.0 seconds</div>
            <div className='opacity-50 text-xs'>
              Average sync time in the Iast 14 days
            </div>
            <div className='h-6' />
            <div className='text-3xl text-primary'>2</div>
            <div className='opacity-50 text-xs'>
              Schema changes in the last 30 days
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
