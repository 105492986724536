import { isEmpty, keys } from 'lodash';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';

export interface FormValues {
  username?: string;
  password?: string;
}

export default function Login() {
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const back = searchParams.get('back');

  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: typeof cookies.auth === 'object' ? cookies.auth : {},
  });

  useEffect(() => {
    if (back && !isEmpty(cookies.auth)) {
      navigate(back);
    }
  }, [cookies.auth]);

  return (
    <LayoutBodyCard>
      <form
        className='p-12 max-w-xl mx-auto'
        onSubmit={handleSubmit((values) => {
          /**
           * This is to fix the cookie created without path params.
           * That seems to cause some issue for auth
           * TODO: delete it when we build new auth handling mechanism
           */
          const uCookies = new Cookies();
          const allCookie = uCookies.getAll();

          keys(allCookie).forEach((element) => {
            uCookies.remove(element);
          });

          toast.info('Auth info stored in cookie.');
          // store auth values
          setCookie('auth', values, {
            path: '/',
          });
        })}
      >
        <h2 className='mb-12 font-semibold text-4xl'>Login (alpha)</h2>
        <div className='form-control w-full max-w-xl mb-6'>
          <label className='label'>
            <span className='label-text'>Username</span>
          </label>
          <input
            type='text'
            className='input input-bordered w-full max-w-xl'
            {...register('username')}
          />
        </div>
        <div className='form-control w-full max-w-xl mb-6'>
          <label className='label'>
            <span className='label-text'>Password</span>
          </label>
          <input
            type='password'
            className='input input-bordered w-full max-w-xl'
            {...register('password')}
          />
        </div>
        <div className='flex justify-end'>
          <button
            type='button'
            className='btn ml-3'
            onClick={() => {
              removeCookie('auth', { path: '/' });
              reset({ password: '', username: '' });
            }}
          >
            Logout
          </button>
          <div className='w-3' />
          <button type='submit' className='btn ml-3'>
            Login
          </button>
        </div>
      </form>
    </LayoutBodyCard>
  );
}
