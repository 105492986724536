import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { IoIosArrowBack } from 'react-icons/io';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import { HorizontalLine } from '../../components/HorizontalLine';
import PaleButton from '../../components/interactive/PaleButton';
import { random } from 'lodash';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { SelectedTableSchemaState } from '../database/TableSchemaState';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { DatabasesQueryState } from '../database/DatabaseState';
import {
  ModelDatabase,
  ModelTable,
  ModelSchema,
} from '../../api/__gen__/data-contracts';
import { VerticalLine } from '../../components/VerticalLine';
import { DatabaseApi } from '../../api/client';
import './block-arrow.css';
import { useCookies } from 'react-cookie';
import { UploadPayload } from './types';

interface IProcessingUploadProps {
  payload: UploadPayload;
  onPrev: () => void;
  onNext: () => void;
}

export default function ProcessingUpload({
  payload,
  onPrev,
  onNext,
}: IProcessingUploadProps) {
  const [requestDuration] = useState(random(75, 85));
  const [time, setTime] = useState(0);
  const { s3, database, schema, table } = payload;
  const timerRef = useRef<NodeJS.Timer>();
  const percentage = time / requestDuration;
  const setTableSchema = useSetRecoilState(SelectedTableSchemaState);
  const [{ contents: databaseContents }] =
    useRecoilStateLoadable(DatabasesQueryState);
  const databaseList: ModelDatabase[] = databaseContents || [];
  const [schemas, setSchema] = useState<ModelSchema[]>([]);
  const [tables, setTables] = useState<ModelTable[]>([]);
  const [cookies] = useCookies(['auth']);

  useEffect(() => {
    const uploadTimer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);
    timerRef.current = uploadTimer;
    return () => clearInterval(uploadTimer);
  }, []);

  useEffect(() => {
    if (time >= requestDuration) {
      clearInterval(timerRef.current);
    }
  }, [time]);

  useEffect(() => {
    const fetchData = async () => {
      if (database && schema) {
        const client = DatabaseApi();
        const schemaResp = await client.schemasDetail(database);
        if (Array.isArray(schemaResp.data.items)) {
          setSchema(schemaResp.data.items);
        }
        const tableResp = await client.schemasTablesDetail(database, schema);
        const { items } = tableResp.data;
        if (Array.isArray(items)) {
          setTables(items);
        }
      }
    };
    const upload = async () => {
      const bodyFormData = new FormData();
      if (
        database &&
        schema &&
        table &&
        s3 &&
        cookies &&
        cookies.auth?.username
      ) {
        const { username } = cookies.auth;
        bodyFormData.append('datasource', database);
        bodyFormData.append('database', schema);
        bodyFormData.append('table', table);
        bodyFormData.append('s3_path', s3);
        bodyFormData.append('username', username);
        try {
          const res = await axios.post(
            'https://csv.api.zettablock.com/register',
            bodyFormData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          // eslint-disable-next-line
          console.info(res);
          if (res.data === 'table_exist') {
            toast.error('Table already exists.');
            setTime(requestDuration);
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      }
    };
    fetchData();
    upload();
  }, [payload]);

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      {/* header  */}
      <div className='inline-flex p-6 items-center w-full'>
        <PaleButton
          onClick={() => {
            onPrev();
          }}
        >
          <IoIosArrowBack size='1.8rem' />
        </PaleButton>
        <div className='flex-1' />
        {time >= requestDuration && (
          <button
            className='btn btn-primary btn-wide'
            onClick={() => {
              setTableSchema(table);
              onNext();
            }}
          >
            Create a New Query
          </button>
        )}
      </div>
      <HorizontalLine />
      {/* body */}
      <div className='h-2' />
      <div className='flex flex-col px-8 w-full h-full items-center justify-center'>
        {time >= requestDuration ? (
          <>
            <h3 className='text-2xl font-bold'>Your data is here</h3>
            <h3 className='text-lg'>
              <span className='opacity-50'>Successfully uploaded</span>{' '}
              <span className='font-bold'>{table}</span>
              <span className='opacity-50'>.</span>
            </h3>
            <h3 className='text-lg'>
              <span className='opacity-50'>You can find the data in</span>{' '}
              <span className='font-bold'>{schema}</span>{' '}
              <span className='opacity-50'>after 3-5 minutes.</span>
            </h3>
            <div className='h-5' />
            <div className='card w-1/2 h-full bg-base-300 p-6'>
              {databaseList.map(({ id, displayName }) =>
                id === database ? (
                  <div className='flex flex-col' key={id}>
                    <div className='inline-flex space-x-2 items-center'>
                      <BiChevronDown size='1.5rem' className='opacity-50' />
                      <span className='text-lg'>{displayName}</span>
                    </div>
                    <div className='inline-flex w-full h-full'>
                      <VerticalLine className='h-auto mx-[0.75rem] border-gray-300' />
                      <div className='flex flex-col w-full h-full'>
                        {schemas.slice(0, 5).map(
                          ({ name }, index) =>
                            index < 4 &&
                            name !== schema && (
                              <div
                                className='inline-flex space-x-2 items-center h-full'
                                key={`${name} ${index}`}
                              >
                                <BiChevronRight
                                  size='1.5rem'
                                  className='opacity-50'
                                />
                                <span className='text-lg'>{name}</span>
                              </div>
                            )
                        )}
                        {schemas.length > 4 && (
                          <div className='inline-flex space-x-2 items-center h-full py-2'>
                            <VerticalLine className='h-[1.5rem] mx-[0.75rem] border-gray-300' />
                            <span className='text-lg'>
                              ...... more databases
                            </span>
                          </div>
                        )}
                        <div className='flex flex-col'>
                          <div className='inline-flex space-x-2 items-center h-full'>
                            <BiChevronDown
                              size='1.5rem'
                              className='opacity-50'
                            />
                            <span className='text-lg'>{schema}</span>
                          </div>
                          <div className='inline-flex w-full h-full'>
                            <VerticalLine className='h-auto mx-[0.75rem] border-gray-300' />
                            <div className='flex flex-col w-full h-full'>
                              {tables.slice(0, 5).map(
                                ({ name }, index) =>
                                  index < 4 &&
                                  name !== table && (
                                    <div
                                      className='inline-flex space-x-2 items-center h-full'
                                      key={`${name} ${index}`}
                                    >
                                      <BiChevronRight
                                        size='1.5rem'
                                        className='opacity-50'
                                      />
                                      <span className='text-lg'>{name}</span>
                                    </div>
                                  )
                              )}
                              {tables.length > 4 && (
                                <div className='inline-flex space-x-2 items-center h-full py-2'>
                                  <VerticalLine className='h-[1.5rem] mx-[0.75rem] border-gray-300' />
                                  <span className='text-lg'>
                                    ...... more tables
                                  </span>
                                </div>
                              )}
                              <div className='inline-flex space-x-2 items-center h-full'>
                                <BiChevronRight
                                  size='1.5rem'
                                  className='opacity-50'
                                />
                                <span className='text-lg'>{table}</span>
                                <div className='inline-flex items-center'>
                                  <div className='w-4' />
                                  <div className='blockArrow flex items-center h-full justify-center px-2 text-primary bg-primary'>
                                    <span className='text-white'>
                                      Your data is here
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='inline-flex space-x-2 items-center' key={id}>
                    <BiChevronRight size='1.5rem' className='opacity-50' />
                    <span className='text-lg'>{displayName}</span>
                  </div>
                )
              )}
            </div>
            <div className='h-10' />
            <button
              className='btn btn-primary btn-wide btn-md'
              onClick={() => {
                setTableSchema(table);
                onNext();
              }}
            >
              Create a New Query
            </button>
          </>
        ) : (
          <>
            <h3 className='text-3xl'>Uploading CSV to Database</h3>
            <span className='text-base font-bold m-0 opacity-40'>
              Est. {requestDuration} seconds
            </span>
            <div className='h-10' />
            <progress
              className='progress max-w-2xl h-3 progress-primary'
              value={time}
              max={requestDuration}
            />
            <div className='h-2' />
            <div className='inline-flex max-w-2xl w-full'>
              <h3 className='text-lg font-bold'>
                {percentage < 0.3
                  ? 'Creating'
                  : `${percentage < 0.9 ? 'Processing' : 'Ready'}`}
              </h3>
              <div className='flex-1' />
              <h3 className='text-lg font-bold'>
                {(percentage * 100).toFixed(0)}%
              </h3>
            </div>
            <div className='h-10' />
          </>
        )}
      </div>
      <div className='h-2' />
    </LayoutBodyCard>
  );
}
