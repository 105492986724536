import { AiOutlinePlus } from 'react-icons/ai';
import Logo from '../../components/app/Logo';
import GitHubLogo from './components/GitHubLogo';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import { HorizontalLine } from '../../components/HorizontalLine';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { VerticalLine } from '../../components/VerticalLine';

export default function Transformations() {
  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      {/* header  */}
      <div className='flex p-6 items-center'>
        <BodyHeader3 className='flex-0'>Transformations</BodyHeader3>
      </div>
      <HorizontalLine />
      {/* body */}
      <div className='py-5 px-6 items-center'>
        {/* ZettaBlock + GitHub */}
        <div className='inline-flex  py-10 my-5 items-center justify-center space-x-2 bg-base-200 w-full rounded-2xl'>
          <Logo className='w-[15rem]' />
          <AiOutlinePlus size='1.5rem' />
          <div className='w-1' />
          <GitHubLogo
            containerClassName='w-[10rem]'
            iconClassName='w-1/4'
            textClassName='w-3/4'
          />
        </div>

        <div className='inline-flex py-10 my-5 justify-center space-x-2 w-full min-h-full'>
          {/* Overview */}
          <div className='flex-1 p-10'>
            <BodyHeader3>Bring transformations into production</BodyHeader3>
            <div className='h-5'></div>
            <ul className='list-disc mx-5 space-y-3 text-lg opacity-50'>
              <li>Natively run data transformation jobs on a schedule</li>
              <li>
                Integrate with any git repository by using a git URL that hosts
                valid transformation SQLs
              </li>
              <li>
                View the current status and historical logs of each
                transformation job
              </li>
            </ul>
          </div>
          <VerticalLine />
          {/* Requirements */}
          <div className='flex-1 p-10'>
            <BodyHeader3>
              Required: A dbt project in a Git repository
            </BodyHeader3>
            <div className='h-5'></div>
            <span className='leading-10 space-y-3 text-lg opacity-50'>
              ZettaBlock will sync your transformations from your Git
              repository. Your transformations repository must have the
              following files before connecting to ZettaBlock.
            </span>
            <div className='h-8' />
            <button
              className='btn btn-primary uppercase w-full'
              onClick={() => {}}
            >
              coming soon
            </button>
            <div className='h-20' />
          </div>
        </div>
      </div>
    </LayoutBodyCard>
  );
}
