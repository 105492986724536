import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import Card from './components/Card';
import SearchBar from './components/SearchBar';
import { DatasetLayoutType } from '../../types';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import { IDataset } from './const';
import organizeDataset from './utils/organizeDataset';
import { DATASETS } from './__tests__/artifacts';
import { deleteQueryParams, updateQueryParams } from '../../utils/queryParams';
import { useSearchParams } from 'react-router-dom';
import getParamsFromQueryParams from './utils/getParamsFromQueryParams';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { HorizontalLine } from '../../components/HorizontalLine';
import classNames from 'classnames';

function DatasetList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    search: searchParam,
    filters: filterParams,
    view_type,
  } = getParamsFromQueryParams(searchParams);
  const [type] = useState<DatasetLayoutType>(view_type);
  const [searchTerm, setSearchTerm] = useState(searchParam);
  const [searchResults, setSearchResults] = useState<Array<IDataset>>([]);
  const [filters] = useState<Set<string>>(filterParams);
  const [, filteredDatasetIds] = organizeDataset(
    searchTerm !== '' ? searchResults : DATASETS,
    filters
  );
  /* 
    This search + filter logic will need to be refactored or reworked. 
    Note if we are doing searching and filtering via REST APIs then this is unnecessary
    and does not need to be further optimized
  */
  const filteredDatasets =
    filters.size > 0
      ? DATASETS.filter(({ id }) => filteredDatasetIds.has(id))
      : DATASETS;
  const datasetsToShow = searchTerm !== '' ? searchResults : filteredDatasets;

  const onSearch = (value: string) => {
    setSearchParams(
      value === ''
        ? deleteQueryParams('search')
        : updateQueryParams('search', value)
    );
    setSearchTerm(value);
  };

  useEffect(() => {
    const initSeachValue = searchParams.get('search');
    if (initSeachValue) {
      const fuse = new Fuse(DATASETS, { keys: ['name'] });
      setSearchResults(fuse.search(initSeachValue).map(({ item }) => item));
    }
  }, []);

  useEffect(() => {
    const _filteredDatasetIds = organizeDataset(DATASETS, filters)[1];
    const fuse = new Fuse(
      filters.size === 0
        ? DATASETS
        : DATASETS.filter(({ id }) => _filteredDatasetIds.has(id)),
      { keys: ['name'] }
    );
    setSearchResults(fuse.search(searchTerm).map(({ item }) => item));
  }, [searchTerm, filters]);

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      {/* Header Section */}
      <div className='flex p-6 items-center'>
        <BodyHeader3 className='flex-1'>Projects</BodyHeader3>
        <div className='flex items-center w-52'>
          <SearchBar onSearch={onSearch} placeholder='Search...' />
        </div>
      </div>
      <HorizontalLine />

      {/* Content Section */}
      <div
        className={classNames(type === 'grid' ? 'flex flex-wrap' : '', 'p-3')}
      >
        {datasetsToShow.map((dataset, index) => (
          <Card key={index} type={type} dataset={dataset} />
        ))}
      </div>
    </LayoutBodyCard>
  );
}

export default DatasetList;
