import { memo, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { useRecoilRefresher_UNSTABLE, useRecoilStateLoadable } from 'recoil';

import Selector from '../../components/inputs/Selector';
import LoadingIndicator from '../../components/LoadingIndicator';
import { DatabaseCache, DatabasesQueryState } from './DatabaseState';
import { isEmpty } from 'lodash';
import { ModelDatabase } from '../../api/__gen__/data-contracts';

function DatabaseSelector({
  value,
  onSelect,
  disabled,
  showLabel,
}: {
  value?: ModelDatabase;
  onSelect: (value?: ModelDatabase) => void;
  disabled?: boolean;
  showLabel?: boolean;
}): ReactElement {
  const [{ state, contents }] = useRecoilStateLoadable(DatabasesQueryState);
  const refresh = useRecoilRefresher_UNSTABLE(DatabasesQueryState);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const cacheValue = DatabaseCache.get('cache');
  const loading = state === 'loading';
  const databaseList: ModelDatabase[] = (loading ? cacheValue : contents) || [];

  useEffect(() => {
    if (!value && !isEmpty(databaseList)) {
      const firstDB = databaseList?.[0];
      onSelect(firstDB);
    }
  }, [databaseList, value]);

  return (
    <div className={classNames('form-control w-full')}>
      {showLabel && (
        <label className='label flex pt-0 opacity-50'>
          <span className='label-text font-semibold'>CATEGORY</span>
          {loading && <LoadingIndicator />}
        </label>
      )}
      <Selector
        disabled={disabled}
        value={value?.id}
        className='select-sm'
        options={databaseList.map((d: ModelDatabase) => ({
          label: d.displayName || '',
          value: d.id || '',
        }))}
        onSelect={(e) => {
          const selected = databaseList.find((i) => i.id === e.target.value);
          onSelect(selected);
        }}
      />
    </div>
  );
}

export default memo(DatabaseSelector);
