/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelExecuteQueryByIdRequest,
  ModelGetQueryDetailResponse,
  ModelListQueryrunResponse,
  ModelSubmitQueryRequest,
  ModelTriggerQueryResponse,
  ModelUpdateQueryCacheRequest,
  ModelUpdateQueryCacheResponse,
  ModelUpdateQueryDisplayRequest,
  ModelUpdateQueryDisplayResponse,
  ModelUpdateQueryTextRequest,
  ModelUpdateQueryTextResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Queries<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description update query cache config
   *
   * @tags example
   * @name CacheCreate
   * @summary update query cache config
   * @request POST:/queries/{id}/cache
   */
  cacheCreate = (
    id: string,
    request: ModelUpdateQueryCacheRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateQueryCacheResponse, any>({
      path: `/queries/${id}/cache`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get query detail
   *
   * @tags example
   * @name DetailDetail
   * @summary get query detail
   * @request GET:/queries/{id}/detail
   */
  detailDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetQueryDetailResponse, any>({
      path: `/queries/${id}/detail`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update query display options
   *
   * @tags example
   * @name DisplayCreate
   * @summary update query display options
   * @request POST:/queries/{id}/display
   */
  displayCreate = (
    id: string,
    request: ModelUpdateQueryDisplayRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateQueryDisplayResponse, any>({
      path: `/queries/${id}/display`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description execute query by query id, returning result in response as a data stream. If the query takes long time, this endpoint will return HTTP 302 code with a queryrun id, e.g. {"queryrunId": "xxx"}. Then the client can poll status and get result using that queryrun id.
   *
   * @tags example
   * @name ExecuteCreate
   * @summary execute query by query id
   * @request POST:/queries/{id}/execute
   */
  executeCreate = (
    id: string,
    request: ModelExecuteQueryByIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/queries/${id}/execute`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list queryruns for a given query
   *
   * @tags example
   * @name QueryrunsDetail
   * @summary list queryruns for a given query
   * @request GET:/queries/{id}/queryruns
   */
  queryrunsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelListQueryrunResponse, any>({
      path: `/queries/${id}/queryruns`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description execute query by query id, this is deprecated, please use /queries/{id}/execute
   *
   * @tags example
   * @name PostQueries
   * @summary execute query by query id, this is deprecated, please use /queries/{id}/execute
   * @request POST:/queries/{id}/run
   */
  postQueries = (
    id: string,
    request: ModelExecuteQueryByIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/queries/${id}/run`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update query text
   *
   * @tags example
   * @name TextCreate
   * @summary update query text
   * @request POST:/queries/{id}/text
   */
  textCreate = (
    id: string,
    request: ModelUpdateQueryTextRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateQueryTextResponse, any>({
      path: `/queries/${id}/text`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description trigger query by query id in async way, returning a queryrun id, which could be checked for status
   *
   * @tags example
   * @name TriggerCreate
   * @summary trigger query by query id in async way
   * @request POST:/queries/{id}/trigger
   */
  triggerCreate = (
    id: string,
    request: ModelSubmitQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelTriggerQueryResponse, any>({
      path: `/queries/${id}/trigger`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
