import { MdLink, MdSync, MdAccessTimeFilled, MdRefresh } from 'react-icons/md';
import { FaBell, FaCalendarCheck } from 'react-icons/fa';
import { Navigate, Route, Routes, useParams } from 'react-router';

import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import googlesheet from '../../assets/icons/gsheets 2.svg';
import layoutBlock from '../../assets/icons/layout-block.svg';
import layoutArrange from '../../assets/icons/layout-arrange.svg';
import groupChat from '../../assets/icons/group-chat.svg';
import toggle from '../../assets/icons/toggle.svg';
import Status from './tabs/Status';
import { VerticalLine } from '../../components/VerticalLine';
import ComingSoon from './tabs/ComingSoon';
import BodyHeader3 from '../../components/typographic/BodyHeader3';

export default function ConnectorView() {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <div className='flex'>
        <div className='w-12'>
          <img src={googlesheet} />
        </div>
        <div className='w-3' />
        <div className='flex flex-col'>
          <div className='text-xs opacity-50'>Google Sheets</div>
          <BodyHeader3>Connector 1</BodyHeader3>
        </div>
        <div className='flex-1' />
        <div className='flex'>
          <div className='flex flex-col items-center'>
            <div className='flex-1 pt-1 h-7 opacity-30 cursor-pointer'>
              <FaBell size='1.4rem' />
            </div>
            <div className='h-1' />
            <div className='text-sm opacity-50'>ON</div>
          </div>
          <div className='w-5' />
          <div className='flex flex-col items-center'>
            <div className='h-7 opacity-30 cursor-pointer'>
              <MdRefresh size='1.8rem' />
            </div>
            <div className='h-1' />
            <div className='text-sm opacity-50'>SYNC NOW</div>
          </div>
          <div className='w-5' />
          <div className='flex flex-col items-center'>
            <div className='h-7 cursor-pointer'>
              <input
                type='checkbox'
                className='toggle toggle-primary cursor-pointer'
                defaultChecked
              />
            </div>
            <div className='h-1' />
            <div className='text-sm opacity-50'>ENABLED</div>
          </div>
          <div className='w-5' />
        </div>
      </div>
      <div className='h-6' />
      <LayoutBodyCard className='lg:basis-2/5 basis-full'>
        {/* header  */}
        <div className='flex py-5 px-6 items-center'>
          {[
            {
              title: 'Status',
              path: 'status',
              icon: <img src={layoutBlock} />,
            },
            {
              title: 'Logs',
              path: 'logs',
              icon: <img src={layoutArrange} />,
            },
            {
              title: 'Schema',
              path: 'schema',
              icon: <img src={groupChat} />,
            },
            {
              title: 'Usage',
              path: 'usage',
              icon: <img src={toggle} />,
            },
            {
              title: 'Setup',
              path: 'setup',
              icon: <img src={toggle} />,
            },
          ].map((tab) => (
            <NavLink
              key={tab.title}
              className={({ isActive }) =>
                classNames(
                  'flex mr-4 px-4 py-2 items-center rounded-xl',
                  isActive ? 'bg-base-300 opacity-100' : 'opacity-50'
                )
              }
              to={`/connectors/${id}/${tab.path}`}
            >
              {tab.icon} <span className='ml-2'>{tab.title}</span>
            </NavLink>
          ))}
        </div>
        <div className='bg-base-300 py-2 px-10 flex'>
          <div className='flex flex-1'>
            <div className='flex items-center'>
              <MdAccessTimeFilled
                size='1.2rem'
                className='text-secondary inline'
              />
              <div className='w-2' />
              <div className='text-xs opacity-50'>
                Next sync will run in 3 hours
              </div>
              <div className='w-2' />
            </div>
            <div className='w-2' />
            <VerticalLine className='border-base-content opacity-20 my-1' />
            <div className='w-2' />
            <div className='flex items-center'>
              <FaCalendarCheck
                size='1.0rem'
                className='text-secondary inline'
              />
              <div className='w-2' />
              <div className='text-xs opacity-50'>
                Next sync will run in 3 hours
              </div>
              <div className='w-2' />
            </div>
          </div>
          <div className='flex'>
            <div className='flex items-center'>
              <MdLink size='1.2rem' className='text-secondary inline' />
              <div className='w-2' />
              <div className='text-xs opacity-50'>Connected</div>
              <div className='w-2' />
            </div>
            <div className='w-2' />
            <VerticalLine className='border-base-content opacity-20 my-1' />
            <div className='w-2' />
            <div className='flex items-center'>
              <MdSync size='1.0rem' className='inline' />
              <div className='w-2' />
              <div className='text-xs opacity-50'>
                Last sync completed 3 hours ago
              </div>
              <div className='w-2' />
            </div>
          </div>
        </div>
        {/* body */}
        <Routes>
          <Route index element={<Navigate to='status' replace />} />
          <Route path='status' element={<Status />} />
          <Route path='logs' element={<ComingSoon />} />
          <Route path='schema' element={<ComingSoon />} />
          <Route path='usage' element={<ComingSoon />} />
          <Route path='setup' element={<ComingSoon />} />
        </Routes>
      </LayoutBodyCard>
    </div>
  );
}
