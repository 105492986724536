import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ISearchBarProps {
  onSearch: (keyword: string) => void;
  placeholder: string;
}
function SearchBar({ onSearch, placeholder }: ISearchBarProps) {
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get('search') || '';
  const ref = useRef<HTMLInputElement>(null);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (ref.current?.value !== undefined) onSearch(ref.current.value);
  };

  return (
    <form className='form-control flex w-full' onSubmit={onSubmit}>
      <input
        ref={ref}
        defaultValue={searchParam}
        name='keyword'
        type='text'
        placeholder={placeholder}
        className='input input-bordered w-full'
      />
    </form>
  );
}

export default SearchBar;
